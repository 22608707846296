@import "@/styles/bootstrap/scss/_variables.scss";
@font-face {
    font-family: 'SVN-Gotham';
    src: local('SVN-Gotham'),
        url('SVN-Gotham.woff2') format('woff2'),
        url('SVN-Gotham.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

