.nav_img{
    margin-right: 15px;
}

@media only screen {
.header_logo{
    cursor: pointer;
    display: flex;
    width: 35%;
}
.nav_img{
    width: 55px;
    margin-right: 10px;
}
.site_name{
    margin-top: auto;
    margin-bottom: auto; 
    color: #fff; 
    font-size: 26px;
}
    .header_action{
        width: 60%;
    }
}
@media(max-width: 450px) {
    .header_logo{
        cursor: pointer;
        display: flex;
        width: 55%;
        height: 50%;
    }
    .nav_img{
        width: 55px;
        height: 55px;
    }
    .site_name{
        margin-top: auto;
        margin-bottom: auto; 
        color: #fff; 
        font-size: 18px;
    }
    .header_action{
        width: 45%;
    }
}