@import "@/styles/bootstrap/scss/_variables.scss";
@font-face {
    font-family: 'UTM Times';
    src: url('UTMTimes.eot');
    src: local('UTM Times'), local('UTMTimes'),
        url('UTMTimes.eot?#iefix') format('embedded-opentype'),
        url('UTMTimes.woff2') format('woff2'),
        url('UTMTimes.woff') format('woff'),
        url('UTMTimes.ttf') format('truetype'),
        url('UTMTimes.svg#UTMTimes') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

