@import "@/styles/bootstrap/scss/_variables.scss";
@font-face {
    font-family: 'UTM Times bold';
    src: url('UTMTimesBold.eot');
    src: local('UTMTimesBold'),
        url('UTMTimesBold.eot?#iefix') format('embedded-opentype'),
        url('UTMTimesBold.woff2') format('woff2'),
        url('UTMTimesBold.woff') format('woff'),
        url('UTMTimesBold.ttf') format('truetype'),
        url('UTMTimesBold.svg#UTMTimesBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

