@import "@/styles/bootstrap/scss/_variables.scss";
@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.eot');
    src: url('Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype'),
        url('Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

